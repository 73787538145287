<template>
  <div>
    <b-card no-body>
      <validation-observer ref="formRef">
        <b-form @submit.prevent>
          <b-card-body class="mb-2">
              <b-row>
                <!-- start date  -->
                <b-col cols="12" md="3">
                  <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="dateStart"
                      rules="required"
                    >
                    <flat-pickr
                      id="dateStart"
                      :config="dateFormat"
                      v-model="form.dateStart"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                      placeholder="Start Date"
                    />
                      <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- start end  -->
                <b-col cols="12" md="3">
                  <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="dateEnd"
                      rules="required"
                    >
                    <flat-pickr
                      id="dateEnd"
                      :config="dateFormat"
                      v-model="form.dateEnd"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                      placeholder="End Date"
                    />
                      <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- supplaier  -->
                <b-col cols="12" md="3">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="contactId"
                      rules="required"
                    >
                    <v-select
                        inputId="supplier"
                        ref="firstFocusSelectInput"
                        v-model="form.contactId"
                        :options="LOV.suppliers"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        :placeholder="!actions.isPreview ? $t('globalSingular.supplier') : null"
                      />      
                      <small class="text-danger">{{ errors[0]}}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Button Filter -->
                <b-col cols="12" md="3">
                  <b-button block variant="primary" @click="showingForm" class="mt-2 pd-1">
                    {{ $t('globalActions.generate') }}
                  </b-button>
                </b-col>
              </b-row>
          </b-card-body>

          <!-- table -->
          <b-table
            v-if="showForm"
            id="refListTable"
            ref="refListTable"
            :fields="tableColumns"
            :items="form.details"
            sticky-header
            responsive
            primary-key="id"
            show-empty
            small
            :busy="tableBusy"
            :empty-text="$t('globalSingular.noMatching')"
            class="position-relative"
          >

           <template #table-busy>
              <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle" />
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
              </div>
            </template>

              <!-- Column: code -->
            <template #cell(code)="{ item }">
              <span>
                {{ item.code }}
              </span>
            </template>

            <template #cell(date)="{ item }">
              <span>
                {{ item.date }}
              </span>
            </template>

          <template v-for="(period, idxPeriod) in periodItems" v-slot:[`cell(${period.key})`]="{ item, index }" >
              <div :key="period.key" >
                <cleave
                  v-if="!item.isAccountParent"
                  v-model.number="form.details[index].amounts[idxPeriod].amount"
                  :class="['w-auto text-right form-control form-control-sm', periodItems.length <= 2 && 'width-100-per']"
                  :options="numeric"
                  :disabled="actions.isPreview"
                  placeholder="1 or 3 or 5"
                />
              </div>
            </template>
          </b-table>
          <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between">
            <b-button
              class="mb-sm-1 mb-md-0"
              variant="outline-primary"
              :to="{ name: 'apps-transactions-evaluation-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
              />
              {{ $t('globalActions.backToList') }}
            </b-button>

            <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
    BCard,
    BForm,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BSpinner
}from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useFormResource from '@/comp-functions/useFormResource'
import flatPickr from 'vue-flatpickr-component'
import { ref, onMounted } from '@vue/composition-api'
import storestate from '@/store/index'
import vSelect from 'vue-select'
import { required } from '@validations'
import useHttp from '@/comp-functions/useHttp'
import Cleave from 'vue-cleave-component'
// import { format,parse } from 'date-fns'

const evaluation = [
    { key: 'one', label: 'A', thClass: 'text-center', tdClass: 'align-middle text-center' },
    { key: 'two', label: 'B', thClass: 'text-center', tdClass: 'align-middle text-center' },
    { key: 'three', label: 'C', thClass: 'text-center', tdClass: 'align-middle text-center' },
    { key: 'four', label: 'D', thClass: 'text-center', tdClass: 'align-middle text-center' },
]

export default {
  components: {
    BCard,
    ValidationObserver,
    BForm,
    BCardBody,
    BRow,
    BCol,
    ValidationProvider,
    flatPickr,
    BFormGroup,
    vSelect,
    BButton,
    BTable,
    BSpinner,
    Cleave
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/evaluations', localeContextPath: 'apps.budgeting.singular.budgeting', redirectPathName: 'apps-transactions-evaluation' })
    
    const form = ref({
        dateStart: '',
        dateEnd: '',
        contactId: '',
        details: [
        ]
    })

    const LOV = ref({
        suppliers: []
    })


    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const getSuppliers = async () => {
      LOV.value.suppliers = await get({ url: 'value/contact/supplier' })
    }

    const showForm = ref(false)
    const tableBusy = ref(false)
    const tableColumns = ref([
          { key: 'code', label: 'No Po.', stickyColumn: true },
          { key: 'date', label: 'Tanggal Po.', stickyColumn: true }
      ])

    const { $get } = useHttp()

    const getNumberPO = async () => {
      const startDate = form.value.dateStart
      const endDate = form.value.dateEnd
      const supplaier = form.value.contactId

      const params = [
        `startDate=${startDate}`,
        `endDate=${endDate}`,
        `supplaier=${supplaier ?? ''}`
      ]
      const { data } = await $get({ url: `transaction/evaluation/getPo?${ params.join('&') }`})
      return data
    }

    const showingForm = async () => {
      form.value.details = []
      const validated = await formRef.value.validate()
      if (validated) {
        showForm.value = true
        tableBusy.value = true
        tableColumns.value = [
          { key: 'code', label: 'No Po.', stickyColumn: true },
          { key: 'date', label: 'Tanggal Po.', stickyColumn: true }
        ]
        const getNoPO = await getNumberPO()
        // console.log(getNoPO)
        setPeriodItems()
        getNoPO.forEach(field => {
          const columns = {}
          columns.amounts = []
          columns.code = field.code
          columns.date = field.date
          periodItems.value.forEach(item => {
            const amountPeriod = {
              period: item.key,
              amount: null
            }
            columns.amounts.push(amountPeriod)
          })
            form.value.details.push(columns) 
        })
        // console.log(form.value.details)
        tableBusy.value = false
      }
    }

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const periodItems = ref([])
    const setPeriodItems = () => {
        periodItems.value = [...evaluation]
        tableColumns.value = [...tableColumns.value, ...evaluation]
    }

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      getSuppliers()
    })

    const getDataPreview = async () => {
        // const getNoPO = await getNumberPO()
        const data = await show()
        form.value.contactId = data.contact_id
        form.value.dateStart = data.periode_start
        form.value.dateEnd = data.periode_end 
        showForm.value = true
        tableBusy.value = true
        tableColumns.value = [
          { key: 'code', label: 'No Po.', stickyColumn: true },
          { key: 'date', label: 'Tanggal Po.', stickyColumn: true }
        ]
        setPeriodItems()
        data.details.forEach(field => {
          // let dateUntil = parse(field.tanggal_po, 'dd/MM/yyyy', new Date())
          // let formatUntilDate = format(dateUntil, 'd MMMM yyyy')
          // console.log(formatUntilDate)
          const columns = {}
          columns.amounts = []
          columns.code = field.getCodePO.code
          columns.date =  field.getCodePO.date
          periodItems.value.forEach(item => {
            let values 
            if(item.key === 'one'){
              values = field.penilaian_a 
            }else if(item.key == 'two'){
              values = field.penilaian_b 
            }else if(item.key == 'three'){
              values = field.penilaian_c
            }else{
              values = field.penilaian_d
            }
            const amountPeriod = {
              period: item.key,
              amount: values
            }
            columns.amounts.push(amountPeriod)
          })
            form.value.details.push(columns) 
            tableBusy.value = false
        })
    }

    return {
        formRef,
        form,
        dateFormat,
        show,
        actions,
        get,
        paramsId,
        firstFocusInput,
        changeToEdit,
        destroy,
        store,
        LOV,
        showingForm,
        showForm,
        numeric,
        tableColumns,
        tableBusy,
        required,
        periodItems
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
      async handleSubmit () {
         this.store({
           $swal: this.$swal,
           data: this.form
         })
       }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>
