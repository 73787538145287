var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card-body',{staticClass:"mb-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"dateStart","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control form-control-sm",attrs:{"id":"dateStart","config":_vm.dateFormat,"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"Start Date"},model:{value:(_vm.form.dateStart),callback:function ($$v) {_vm.$set(_vm.form, "dateStart", $$v)},expression:"form.dateStart"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"dateEnd","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control form-control-sm",attrs:{"id":"dateEnd","config":_vm.dateFormat,"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"End Date"},model:{value:(_vm.form.dateEnd),callback:function ($$v) {_vm.$set(_vm.form, "dateEnd", $$v)},expression:"form.dateEnd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contactId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"firstFocusSelectInput",staticClass:"select-size-sm",attrs:{"inputId":"supplier","options":_vm.LOV.suppliers,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('globalSingular.supplier') : null},model:{value:(_vm.form.contactId),callback:function ($$v) {_vm.$set(_vm.form, "contactId", $$v)},expression:"form.contactId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-button',{staticClass:"mt-2 pd-1",attrs:{"block":"","variant":"primary"},on:{"click":_vm.showingForm}},[_vm._v(" "+_vm._s(_vm.$t('globalActions.generate'))+" ")])],1)],1)],1),(_vm.showForm)?_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","fields":_vm.tableColumns,"items":_vm.form.details,"sticky-header":"","responsive":"","primary-key":"id","show-empty":"","small":"","busy":_vm.tableBusy,"empty-text":_vm.$t('globalSingular.noMatching')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"my-2 text-center text-primary d-flex flex-column justify-content-center"},[_c('div',{staticClass:"mb-1 text"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]},proxy:true},{key:"cell(code)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.date)+" ")])]}},_vm._l((_vm.periodItems),function(period,idxPeriod){return {key:("cell(" + (period.key) + ")"),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{key:period.key},[(!item.isAccountParent)?_c('cleave',{class:['w-auto text-right form-control form-control-sm', _vm.periodItems.length <= 2 && 'width-100-per'],attrs:{"options":_vm.numeric,"disabled":_vm.actions.isPreview,"placeholder":"1 or 3 or 5"},model:{value:(_vm.form.details[index].amounts[idxPeriod].amount),callback:function ($$v) {_vm.$set(_vm.form.details[index].amounts[idxPeriod], "amount", _vm._n($$v))},expression:"form.details[index].amounts[idxPeriod].amount"}}):_vm._e()],1)]}}})],null,true)}):_vm._e(),_c('div',{staticClass:"pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":"outline-primary","to":{ name: 'apps-transactions-evaluation-list' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }